<template>
  <div>
    <!-- Menu::Start -->
    <div class="container mt-4">
      <div class="mt-auto" style="position: relative; top: 17px">
        <div class="row">
          <div class="col-md-4">
            <img
              style="max-width: 300px"
              :src="config.city_logo"
              alt="LOGO DESA"
            />
          </div>
          <div class="col-md-8">
            <div class="d-flex align-items-center">
              <table>
                <tr>
                  <td class="font-weight-bold item-custom py-2" colspan="3" style="color: #34814d">
                    Kontak desa 
                  </td>
                </tr>
                <tr v-if="config.web_url != ''">
                  <td class="item-custom py-1" style="width: 250px">Website Desa</td>
                  <td class="item-custom py-1 text-center" style="width: 30px">:</td>
                  <td class="item-custom py-1">{{ config.web_url }}</td>
                </tr>
                <tr v-if="config.village_email != ''">
                  <td class="item-custom py-1" style="width: 250px">Email Desa</td>
                  <td class="item-custom py-1 text-center" style="width: 30px">:</td>
                  <td class="item-custom py-1">{{ config.web_url }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold item-custom py-2" colspan="3" style="color: #34814d">
                    Kontak pejabat desa 
                  </td>
                </tr>
                <tr v-if="config.village_chief_phone != ''">
                  <td class="item-custom py-1" style="width: 250px">Kepala Desa</td>
                  <td class="item-custom py-1 text-center" style="width: 30px">:</td>
                  <td class="item-custom py-1">{{ config.village_chief_phone }}</td>
                </tr>
                <tr v-if="config.deputy_village_chief_phone != ''">
                  <td class="item-custom py-1" style="width: 250px">
                    Wakil Kepala Desa
                  </td>
                  <td class="item-custom py-1 text-center" style="width: 30px">:</td>
                  <td class="item-custom py-1">
                    {{ config.deputy_village_chief_phone }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      village_info: {},
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
  },
  mounted() {
    this.getSetting();
    // this.getInfo();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}

.item-custom{
  font-size: 25px;
}
</style>
